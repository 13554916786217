import React from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselMiniatures = ({ data, ...props }) => {
  // const settings = {
  //   customPaging: function (i) {
  //     const miniatureImage = getImage(data.allFile.edges[i].node);
  //     return (
  //       <a>
  //         <GatsbyImage
  //           image={miniatureImage}
  //           alt="MicroREC Carousel Image"
  //         />
  //       </a>
  //     );
  //   },
  //   dots: true,
  //   dotsClass: 'slick-dots slick-thumb',
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   navButtonsAlwaysVisible: true,
  // };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider
      {...settings}
      sx={{
        '.slick-list': { margin: '0 -5px' },
        '.slick-slide>div': { padding: '0 5px' },
      }}
    >
      {data.map(({ node }, i) => {
        const image = getImage(node);
        return (
          <div>
            <GatsbyImage
              image={image} fadeIn={false} loading="eager"
              key={node.name}
              imgStyle={{
                borderRadius: '5px',
              }}
              style={{
                borderRadius: '5px',
                boxShadow: ' 1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
              }}
            />
          </div>
        );
      })}
    </Slider>
  );
};

export default CarouselMiniatures;
