// /** @jsx jsx */
import { useStaticQuery, graphql } from 'gatsby';
import { useContext } from 'react';
import { myContext } from '../../utils/provider';

const useStripeProducts = () => {
  const data = useStaticQuery(
    graphql`
      query StripeProductQuery {
        allStripePrice(
          filter: {
            active: { eq: true }
            product: { active: { eq: true } }
            metadata: { Ecommerce_Price: { eq: "true" } }
          }
        ) {
          nodes {
            id
            active
            unit_amount
            type
            currency
            tax_behavior
            metadata {
              USA_Price
              EU_country
              Shipping_Cost
            }
            product {
              id
              name
              metadata {
                DisplayName
                DisplayDescription
              }
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        missingImage: file(name: { eq: "missing-image" }) {
          childImageSharp {
            gatsbyImageData(
              height: 500
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );

  const context = useContext(myContext);

  const rawProducts = data.allStripePrice.nodes;

  // Fallbacks in case values are missing from stripe, fail gracefully
  const missingImage = data.missingImage.childImageSharp.gatsbyImageData;

  // Filter prices if the user is in the US
  const filterProducts = rawProducts.filter((node) => {
    if (context.country === 'US') {
      return node.metadata.USA_Price === 'true';
    } else {
      return node.metadata.USA_Price !== 'true';
    }
  });

  const products = filterProducts.map((node) => {
    // Check to make sure there is an image from Stripe otherwise serve a missing image placeholder
    const hasImage =
      node.product?.localFiles != null &&
      node.product?.localFiles[0]?.childImageSharp?.gatsbyImageData != null;
    const productImage = hasImage
      ? node.product?.localFiles[0]?.childImageSharp?.gatsbyImageData
      : missingImage;

    // const localOnlyValue = node.product?.metadata.localOnly === "true"
    const shippingRegionEUValue =
      node.metadata.EU_country === 'true'
        ? true
        : node.metadata.EU_country === 'false'
        ? false
        : null;
    const productFormatted = {
      name: node.product.name,
      //   description: productDescription,
      price_id: node.id,
      price: node.unit_amount,
      image: productImage,
      currency: node.currency,
      displayName: node.product.metadata.DisplayName,
      displayDescription: node.product.metadata.DisplayDescription,
      product_id: node.product.id,
      tax_behavior: node.tax_behavior,
      //   stock: parseInt(node.product.metadata.stock),
      shippingOption: node.metadata.Shipping_Cost,
      shippingRegionEU: shippingRegionEUValue,
      //   localOnly: localOnlyValue,
    };
    return productFormatted;
  });
  return products;
};

export default useStripeProducts;
