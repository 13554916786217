import React from 'react';

import { Button } from 'gatsby-theme-material-ui';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

import { useShoppingCart } from 'use-shopping-cart/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useWatch, useFormState } from 'react-hook-form';
import { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { removeItemFromCartEventGA } from '../../utils/ga-events';

const CartItem = ({ product, register, control, clearErrors }) => {
  const productId = product.id;

  const { removeItem, setItemQuantity } = useShoppingCart();

  const quantity = useWatch({
    control,
    name: product.id,
    defaultValue: product.quantity, // default value before the render
  });

  const { errors } = useFormState({
    control,
  });

  useEffect(() => {
    const selectedQuantity = parseInt(quantity);
    if (Number.isInteger(selectedQuantity) && selectedQuantity > 0) {
      setItemQuantity(product.id, selectedQuantity);
    }
  }, [quantity]); //eslint-disable-line

  const handleClearItem = () => {
    clearErrors(productId);
    removeItem(product.id);
    removeItemFromCartEventGA(product);
  };

  return (
    // <li
    //   sx={{
    //     mb: 2,
    //     display: 'flex',
    //     gap: 3,
    //     width: '100%',
    //     alignItems: 'center',
    //     ':last-of-type': { mb: 0, border: 'none' },
    //     borderBottomWidth: '1px',
    //     borderBottomStyle: 'solid',
    //     borderBottomColor: 'muted',
    //   }}
    // >
    <Grid
      container
      justifyContent={'space-between'}
      id="ShoppingCart-ProductContainer"
      // maxWidth={'80%'}
      sx={{ backgroundColor: '#F9F9F9' }}
      padding={{ xs: 1, sm: 3 }}
      margin={1}
      alignItems={'center'}
    >
      <Grid item xs={4} sm={2}>
        <GatsbyImage
          image={product.image} fadeIn={false} loading="eager"
          alt={product.name}
          sx={{ height: '100px', width: '100px', borderRadius: '4px' }}
        />
      </Grid>
      <Grid item xs={12} sm={5} order={{ xs: 2, sm: 2 }}>
        <Typography>{product.displayName}</Typography>
        <Typography
          sx={{ color: '#717171' }}
          variant="caption"
          gutterBottom={true}
          component="p"
        >
          {product.displayDescription}
        </Typography>
        <Button
          // sx={{
          //   display: 'block',
          //   color: 'text',
          //   textDecoration: 'underline',
          //   p: 0,
          //   bg: 'transparent',
          //   border: 'none',
          //   // fontSize: [0, null, 1, null, null],
          //   fontWeight: 'normal',
          //   ':hover, :focus, :active': {
          //     bg: 'transparent',
          //     color: 'text',
          //   },
          // }}
          variant="text"
          onClick={handleClearItem}
          startIcon={<DeleteIcon />}
        >
          Remove item
        </Button>
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={1}
        textAlign={'center'}
        order={{ xs: 1, sm: 3 }}
      >
        {/* <label htmlFor={productId} sx={{ fontSize: 0, mr: 1 }}>
          Quantity:
        </label> */}
        <TextField
          id="outlined-number"
          type="number"
          {...register(productId, {
            valueAsNumber: true,
            min: 1,
            max: product.stock,
          })}
          defaultValue={product.quantity}
          min="1"
          max="99"
        />
        {/* <input
          {...register(productId, {
            valueAsNumber: true,
            min: 1,
            max: product.stock,
          })}
          defaultValue={product.quantity}
          min="1"
          max="99"
          type="number"
          // sx={{
          //   fontSize: 0,
          //   lineHeight: 'tight',
          //   px: 1,
          //   py: '2px',
          //   borderColor: 'textGray',
          //   borderRadius: '2px',
          //   borderStyle: 'solid',
          //   borderWidth: '1px',
          // }}
        /> */}
        {errors[productId]?.type === 'min' && (
          <p
            sx={{
              my: 0,
              fontSize: 0,
              color: 'error',
            }}
          >
            Please select a valid quantity
          </p>
        )}
      </Grid>
      <Grid item xs={4} sm={2} textAlign={'center'} order={{ xs: 1, sm: 4 }}>
        <Typography>{product.formattedPrice} </Typography>
      </Grid>
    </Grid>
    // </li>
  );
};

export default CartItem;
