// require('dotenv').config({
//   path: `.env.${process.env.NODE_ENV}`,
// });

import React, { useState } from 'react';

import { useContext } from 'react';
import { myContext } from '../../utils/provider';

import { useStaticQuery, graphql } from 'gatsby';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Button } from 'gatsby-theme-material-ui';
import { useShoppingCart } from 'use-shopping-cart/react';
import CartItem from './cartItem';
// import CartShipping from "./cartShipping"
// import { getCartCount } from "../../utils/getCartCount"
// import { useStripeShipping } from "../../data/useStripeShipping"
import { useForm } from 'react-hook-form';

import useStripeProducts from '../products/useStripeProducts';
import {
  begingCheckoutEventGA,
  removeAllItemsFromCartEventGA,
} from '../../utils/ga-events';
import { begingCheckoutEventFB } from '../../utils/fb-events';

const CartForm = () => {
  const countries = useStaticQuery(graphql`
    query {
      euCountryList: allEuCountriesJson {
        nodes {
          country
          countryCode
        }
      }
      allCountryList: allAllCountriesJson {
        nodes {
          country
          countryCode
        }
      }
      blockedCountryList: allBlockedCountriesJson {
        nodes {
          country
          countryCode
        }
      }
    }
  `);

  const euCountryList = countries.euCountryList.nodes;
  const allCountryList = countries.allCountryList.nodes;
  const blockedCountryList = countries.blockedCountryList.nodes;

  const [open, setOpen] = React.useState(false);

  const context = useContext(myContext);

  const allStripeProducts = useStripeProducts();

  // Get the required functions from USC
  const {
    formattedTotalPrice,
    totalPrice,
    redirectToCheckout,
    clearCart,
    cartDetails,
    handleCloseCart,
    cartCount,
  } = useShoppingCart();

  // // Check if there are any localOnly products
  // const hasLocalOnly = Object.values(cartDetails).some(
  //   (product) => product.localOnly === true
  // );

  // Track whether checkout has been clicked
  const [clickedCheckout, setClickedCheckout] = useState(false);

  // Setup the form for the cart
  const {
    handleSubmit,
    register,
    control,
    clearErrors,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  // Structure cart items so it has the shape Stripe is expecting
  // price & quantity only
  const formatCartItems = (cartProducts) => {
    const freeShippingProduct = allStripeProducts.find(
      (item) => item.name === 'Free Shipping' && item.shippingOption === 'true'
    );

    const shippingCostProduct = getShippingProduct();
    const formatedCartItems = [];

    for (const id in cartProducts) {
      const item = {
        price: cartDetails[id].price_id,
        quantity: cartDetails[id].quantity,
      };

      formatedCartItems.push(item);
    }
    if (totalPrice < 99900) {
      const item = {
        price: shippingCostProduct.price_id,
        quantity: 1,
      };
      formatedCartItems.push(item);
    } else {
      const item = {
        price: freeShippingProduct.price_id,
        quantity: 1,
      };
      formatedCartItems.push(item);
    }
    return formatedCartItems;
  };

  // Get Allowed Countries
  const getAllowedCountries = () => {
    const allowedCountries = allCountryList.filter(
      (item) =>
        !blockedCountryList.find(
          (rm) =>
            rm.country === item.country && item.countryCode === rm.countryCode
        )
    );

    let allowedCountriesCodes = allowedCountries.map((x) => x.countryCode);
    return allowedCountriesCodes;
  };

  const getShippingProduct = () => {
    let euCountry = euCountryList.some((country) => {
      if (country.countryCode === context.country) {
        return true;
      } else {
        return false;
      }
    });
    const shippingCostProduct = allStripeProducts.find(
      (element) =>
        element.shippingOption === 'true' &&
        element.shippingRegionEU === euCountry
    );

    return shippingCostProduct;
  };

  // Function to perform the serverless checkout. We use netlify function for this.
  async function checkoutFunction() {
    const response = await fetch(
      `${process.env.NETLIFY_FUNCTIONS_URL}checkout`,
      // 'https://lighthearted-douhua-e991a2.netlify.app/.netlify/functions/checkout',
      // '/.netlify/functions/checkout',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          product: formatCartItems(cartDetails),
          countries: getAllowedCountries(),
        }),
      }
    );

    const data = await response.json();

    redirectToCheckout({ sessionId: data.sessionId });
  }

  // Function for what happens after submit of the form.
  // Needs to split into a seperate function call as RHF
  // does form validation before sending it to handleCheckout
  const onSubmit = () => handleCheckout();

  // Clear the cart
  const handleClearCart = () => {
    removeAllItemsFromCartEventGA(cartDetails, totalPrice);
    clearCart();
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  // Handle clicking the checkout button, using a timeout to reset it after 5 seconds
  const handleCheckout = () => {
    setClickedCheckout(true);
    handleBackDrop();
    setTimeout(() => {
      setClickedCheckout(false);
      clearCart();
      handleCloseCart();
    }, 6000);
    begingCheckoutEventGA(cartDetails, totalPrice);
    begingCheckoutEventFB(cartDetails, totalPrice);
    checkoutFunction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {cartCount > 0 ? (
        <Grid
          container
          id="ShoppingCart-ProductsGridContainer"
          justifyContent={'center'}
        >
          {Object.values(cartDetails)
            .filter((product) => product.shippingOption !== true)
            .map((product) => {
              return (
                <Container>
                  <CartItem
                    key={product.id}
                    product={product}
                    register={register}
                    control={control}
                    clearErrors={clearErrors}
                  />
                  <Divider style={{ width: '100%' }} />
                </Container>
              );
            })}
          <Grid
            container
            justifyContent={'flex-end'}
            margin={1}
            // paddingLeft={{ xs: 1, sm: 3 }}
            // paddingRight={{ xs: 1, sm: 3 }}
            padding={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={3} sm={2}>
              <Typography>Total</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={2}
              // sx={{ backgroundColor: '#F9F9F9' }}
              // padding={3}
              textAlign={'center'}
            >
              <Typography>{formattedTotalPrice}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} padding={{ xs: 1, sm: 3 }} margin={1}>
            <Typography
              sx={{ color: '#B6B0BB' }}
              variant="body2"
              gutterBottom={true}
              textAlign={'right'}
            >
              Prices do not include taxes or shipping&nbsp;cost.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <p sx={{ fontSize: 1 }}>
          Looks like your cart is empty! Take a look around and enjoy something
          fun!
        </p>
      )}
      {/* <div sx={{ display: 'flex', gap: 3 }}> */}
      <Grid
        container
        marginTop={3}
        marginBottom={3}
        paddingLeft={2}
        paddingRight={2}
        // margin={1}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        spacing={2}
      >
        <Button
          type="reset"
          onClick={handleClearCart}
          variant="secondary"
          sx={{ fontWeight: 'normal', width: '120px' }}
          marginButton={2}
        >
          Clear cart
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth="true"
          disabled={!isValid || clickedCheckout || cartCount === 0}
          sx={{
            width: '160px',
            display: 'grid',
            placeItems: 'center',
            ':disabled': {
              opacity: 0.5,
              cursor: 'default',
            },
            ':disabled:hover, :disabled:active, :disabled:focus': {
              bg: 'primary',
            },
          }}
        >
          {clickedCheckout ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            `Go to Checkout`
          )}
        </Button>
      </Grid>
    </form>
  );
};

export default CartForm;
