import React, { useEffect, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import Divider from '@mui/material/Divider';

import { Button } from 'gatsby-theme-material-ui';

import { navigate, useStaticQuery, graphql } from 'gatsby';
import { useShoppingCart } from 'use-shopping-cart/react';

import { addItemToCartEventGA } from '../utils/ga-events.js';
import { addItemToCartEventFB } from '../utils/fb-events.js';

export default function BrandSelect({
  productName,
  data,
  stripeProducts,
  page,
  ...props
}) {
  const compatibilityData = useStaticQuery(graphql`
    query {
      brands2BeamSplitterKit: allBrand2BeamsplitterCompatibilityJson {
        nodes {
          BeamSplitter
          Brand
          Device
          SKU
          Stripe_Product
          kit_product_id
          product_id
        }
      }
      brands2AdapterKit: allBrand2AdapterCompatibilityJson {
        nodes {
          Adapter
          Brand
          Device
          SKU
          Stripe_Product
          product_id
          kit_product_id
        }
      }
    }
  `);
  const brands2AdapterKit = compatibilityData.brands2AdapterKit.nodes;
  const brands2BeamSplitterKit = compatibilityData.brands2BeamSplitterKit.nodes;

  // console.log(brands2AdapterKit);
  // console.log(brands2BeamSplitterKit);

  const [brand, setBrand] = useState('');
  const [hasError, setHasError] = useState(false);
  const [stripeItem, setStripeItem] = useState('');
  const [displayText, setDisplayText] = useState(false);
  const [compatibilityList, setCompatibilityList] = useState('');
  const [open, setOpen] = React.useState(false);
  // Get our functions from u-s-c
  const { addItem } = useShoppingCart();

  // Find the product the customer needs based on the brand selected on the dropdown
  const getStripeItem = (brand) => {
    // Find the MicroREC Kit the customer needs based on the brand selected on the dropdown
    if (page === 'microREC-page') {
      let stripeProduct = stripeProducts.find((item) => {
        return item.product_id === brand.kit_product_id;
      });
      return stripeProduct;
    } else {
      let stripeProduct = stripeProducts.find((item) => {
        // console.log(item);
        return item.product_id === brand.product_id;
      });
      return stripeProduct;
    }
  };

  const getAllCompatibleBrands = (brand) => {
    if (productName === 'Beam Splitter') {
      let allCompatibleBrands = brands2BeamSplitterKit
        .map((item) => {
          if (item.BeamSplitter === brand.BeamSplitter) {
            return item.Brand;
          }
        })
        .filter(Boolean);
      let uniqueCompatibleBrands = [...new Set(allCompatibleBrands)];
      return uniqueCompatibleBrands.sort();
    } else {
      let allCompatibleBrands = brands2AdapterKit
        .map((item) => {
          if (item.Adapter === brand.Adapter) {
            return item.Brand;
          }
        })
        .filter(Boolean);
      let uniqueCompatibleBrands = [...new Set(allCompatibleBrands)];
      return uniqueCompatibleBrands.sort();
    }
  };

  const link2UpsellPage = () => {
    navigate('/accessories/', {
      state: {
        brand: brand,
        stripeItem: stripeItem,
        compatibleBrands: compatibilityList,
      },
    });
  };

  const handleChange = (event) => {
    setBrand(event.target.value);
    setHasError(false);
  };

  // Handle clicking the add item button
  const handleAddItem = () => {
    // console.log(stripeItem);
    addItem(stripeItem);
    addItemToCartEventGA(stripeItem, brand);
    addItemToCartEventFB(stripeItem, brand);
    // handleCartClick();
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (brand) {
      setHasError(false);
      if (page === 'microREC-page') {
        handleAddItem();
        handleBackDrop();
        setTimeout(() => {
          link2UpsellPage();
        }, 400);
      } else {
        handleAddItem();
      }
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    //Runs on the first render
    //And any time any dependency value changes
    if (brand) {
      let item = getStripeItem(brand);
      // console.log(item);
      setStripeItem(item);

      let cList = getAllCompatibleBrands(brand);
      setCompatibilityList(cList);

      if (cList == brand.Adapter) {
        setDisplayText(false);
      } else {
        setDisplayText(true);
      }
    } else {
      setDisplayText(false);
    }
  }, [brand]);

  return (
    <Grid container justifyContent={'center'}>
      <Divider style={{ width: '100%' }} />

      <Grid item xs={12}>
        <Typography mb={2} mt={2}>
          {' '}
          Select your Microscope or Slit Lamp brand&nbsp;here:{' '}
        </Typography>
      </Grid>
      <FormControl fullWidth error={hasError}>
        <InputLabel
          id="demo-simple-select-label"
          required="true"
          style={{ zIndex: '0' }}
        >
          Brand
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={brand}
          label="Device Brand"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <ListSubheader> Microscope </ListSubheader>
          {data.map((item) => {
            if (item.Device == 'Microscope')
              return <MenuItem value={item}>{item.Brand}</MenuItem>;
          })}
          <ListSubheader> Slit Lamp </ListSubheader>
          {data.map((item) => {
            if (item.Device == 'Slit Lamp')
              return <MenuItem value={item}>{item.Brand}</MenuItem>;
          })}
        </Select>
        <FormHelperText>Required</FormHelperText>
      </FormControl>

      {/* Add text with a warning to the user of the MicroREC kit that will be added and which brands are compatible with it */}
      {displayText ? (
        <Typography
          variant="body2"
          alignItems={'center'}
          sx={{ textAlign: 'justify' }}
        >
          <p>
            A <span>{productName}</span> for{' '}
            {productName === 'Beam Splitter' ? (
              <span> {brand.BeamSplitter} </span>
            ) : (
              <span> {brand.Adapter} </span>
            )}
            will be added to the&nbsp;Cart.{' '}
          </p>
          <p>
            This kit is also compatible with:
            <br />
            {compatibilityList.map((item, index) => (
              <span> {(index ? ', ' : '') + item} </span>
            ))}{' '}
          </p>
        </Typography>
      ) : null}
      <Grid item marginTop={2}>
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          endIcon={<AddShoppingCartOutlinedIcon />}
        >
          Add to Cart
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}
