import React from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useShoppingCart } from 'use-shopping-cart/react';

import CartForm from './cartForm';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CartModal = () => {
  const { handleCloseCart, shouldDisplayCart, cartDetails, cartCount } =
    useShoppingCart();

  return (
    <div>
      {shouldDisplayCart && (
        <BootstrapDialog
          onClose={handleCloseCart}
          aria-labelledby="customized-dialog-title"
          open={shouldDisplayCart}
          // xs={10}
          maxWidth={'md'}
          fullWidth={true}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseCart}
            sx={{ color: '#FFA100' }}
          >
            Shopping Cart
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent={'space-between'}
              marginTop={1}
              marginBottom={1}
              // padding={2}
              paddingLeft={3}
              paddingRight={3}
              display={{ xs: 'none', sm: 'flex' }}
            >
              <Grid item xs={8}>
                <Typography>Products</Typography>
              </Grid>
              <Grid item xs={2} textAlign={'center'}>
                <Typography>Quantity</Typography>
              </Grid>
              <Grid item xs={2} textAlign={'center'}>
                <Typography>Price</Typography>
              </Grid>
            </Grid>
            <CartForm />

            {/* <p sx={{ fontSize: 1, color: 'textGray', my: 0 }}>
              {cartCount} {cartCount === 1 ? 'item' : 'items'}
            </p> */}
            {/* <h3 sx={{ fontSize: 3, mt: 0, mb: 4 }}>{formattedTotalPrice}</h3> */}

            {/* <p sx={{ color: 'textGray', fontSize: 0, mt: 3, mb: 0 }}> */}
            <a
              href="https://customsurgical.co/terms-conditions/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
              sx={{
                color: '#B6B0BB',
                ':hover, :active, :focus': {
                  color: '#FFA100',
                },
              }}
            >
              Terms and Conditions
            </a>
            {/* </p> */}
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleCloseCart}>
              CLOSE
            </Button>
          </DialogActions> */}
        </BootstrapDialog>
      )}
    </div>
  );
};

export default CartModal;
